import { gql } from 'graphql-request';
import { Customer } from 'src/shared/types';

export const customersQuery = gql`
    query customer {
        customers {
            customerId
            customerCode
            customerName
        }
    }
`;

export type CustomersQueryResult = {
    customers: Pick<Customer, 'customerId' | 'customerName' | 'customerCode'>[];
};
