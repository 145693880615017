import { useEffect } from 'react';

import { useAppDispatch } from 'src/store';

import { loadStoredCustomerId } from 'src/app/customers/state/customersSlice';
import { useCustomersQuery } from 'src/app/customers/state/api/customersGraphSlice';
import { loadStoredProjectId } from 'src/app/projects/views/project-list/state/projectListSlice';
import { loadStoredRoutesConfig } from 'src/routes/state/routesSlice';
import useAuthService from 'src/shared/hooks/useAuthService';
import { useProjectsQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { once } from 'lodash';

// load redux state from browser storage once when the application mounts
const useRestoredReduxState = () => {
    const dispatch = useAppDispatch();
    const { isAuthenticated } = useAuthService();
    const { currentCustomerId } = useCustomer();

    // Fetch customers list
    useCustomersQuery(
        {},
        {
            skip: !isAuthenticated,
        }
    );

    // Fetch projects list
    useProjectsQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: !currentCustomerId,
        }
    );

    useEffect(() => {
        const restoreState = once(() => {
            if (isAuthenticated) {
                // Projects
                dispatch(loadStoredProjectId());

                // Customers
                dispatch(loadStoredCustomerId());

                // Routes config
                dispatch(loadStoredRoutesConfig());
            }
        });
        restoreState();
    }, []);
};

export default useRestoredReduxState;
