import type { ReactNode } from 'react';

export enum SortingDirection {
    ASC = 'ascending',
    DESC = 'descending',
    NONE = 'none',
}

export type SortedColumn = {
    columnId: string;
    sortedDirection: SortingDirection;
};

export type TableCellStructuredData = { data: unknown };

export type TableCellData = ReactNode | TableCellStructuredData;

export type TableRowData = TableCellData[];

export type TableRow = {
    cells: TableCellData[];
    rowId: string | number;
};

export type TableRows = TableRow[];

export type TableData = TableRowData[];

export type TableCellRendererParams = {
    rowId: string | number;
    rowData: TableRowData;
    cellData: TableCellData;
};

export type TableRowsSortingFunction = (rows: TableRows, columnIndex: number, direction?: SortingDirection) => TableRows;

export type IndividualColumn = {
    columnId: string;
    title?: string;
    isSortable?: boolean;
    isSearchable?: boolean;
    isHideable?: boolean;
    isExportable?: boolean;
    headerCellClassName?: string;
    bodyCellClassName?: string;
    width?: string;
    sortFn?: TableRowsSortingFunction;
    renderer?: (params: TableCellRendererParams) => ReactNode;
    downloadRenderer?: (params: TableCellRendererParams) => string | number;
};

export type DataTableColumns = IndividualColumn[];

export type TableSelectedRowIds = Set<string | number>;

export type RowsPerPage = 10 | 15 | 20 | 50 | 100 | typeof Infinity;

export type DataTableState = {
    data: TableData;
    rowsPerPage: RowsPerPage;
    currentPageNumber: number;
    searchText: string;
    sortedColumn: SortedColumn | null;
    fixedHeader: boolean;
    hiddenColumnIds: TableSelectedRowIds;
};

type SetRowsPerPageAction = {
    type: 'set_rows_per_page';
    payload: RowsPerPage;
};
type SetSearchTextAction = {
    type: 'set_search_text';
    payload: string;
};
type SetCurrentPageNumberAction = {
    type: 'set_current_page_number';
    payload: number;
};

type SetSortedColumnAction = {
    type: 'set_sorted_column';
    payload: SortedColumn;
};

type ClearSortedColumnAction = {
    type: 'clear_sorted_column';
};

type RestoreDefaultsAction = {
    type: 'restore_defaults';
    payload: Partial<DataTableState>;
};

export type AllTableActions =
    | SetRowsPerPageAction
    | SetSearchTextAction
    | SetCurrentPageNumberAction
    | SetSortedColumnAction
    | ClearSortedColumnAction
    | RestoreDefaultsAction;
