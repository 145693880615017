import React from 'react';
import classNames from 'classnames';
import startCase from 'lodash/startCase';
import { type IndividualColumn, SortingDirection } from 'src/shared/components/data-table/dataTableTypes';
import { ReactComponent as SortIcon } from 'src/assets/svg/chevron_down.svg';
import useDataTable from 'src/shared/components/data-table/hooks/useDataTable';
import styles from '../../TableHeader.module.scss';

const TableHeaderCell = (props: IndividualColumn) => {
    const { title, isSortable, columnId, width, headerCellClassName } = props;

    const { dispatch, sortedColumn } = useDataTable();

    const { columnId: sortedColumnId, sortedDirection } = sortedColumn || {};

    if (!dispatch) {
        return null;
    }

    let currentColumnSortingDirection = SortingDirection.NONE;
    if (columnId === sortedColumnId && sortedDirection === SortingDirection.ASC) {
        currentColumnSortingDirection = SortingDirection.ASC;
    }

    const onSort = () => {
        let directionToSet = SortingDirection.NONE;
        if (columnId !== sortedColumnId || sortedDirection === SortingDirection.NONE) {
            directionToSet = SortingDirection.DESC;
        } else if (sortedDirection === SortingDirection.DESC) {
            directionToSet = SortingDirection.ASC;
        } else if (sortedDirection === SortingDirection.ASC) {
            dispatch({
                type: 'clear_sorted_column',
            });
            return;
        }

        dispatch({
            type: 'set_sorted_column',
            payload: {
                columnId,
                sortedDirection: directionToSet,
            },
        });
    };

    const sortArrowClassNames = classNames(styles.sortArrow, {
        [styles.sortArrowActive]: columnId === sortedColumnId,
        [styles.sortArrowUp]: currentColumnSortingDirection === SortingDirection.ASC,
    });

    const headerCellClassNames = classNames(styles.headerCell, {
        [styles.headerCellSortable]: isSortable,
        headerCellClassName,
    });

    const titleClassNames = classNames(styles.title, {
        [styles.titleSortable]: isSortable,
        [styles.titleActive]: columnId === sortedColumnId,
    });

    const sortArrow = (
        <div onClick={isSortable ? onSort : undefined} className={styles.sortArrowContainer}>
            <SortIcon className={sortArrowClassNames} data-testid='sort-arrow' />
        </div>
    );

    return (
        <th className={headerCellClassNames} style={{ width, minWidth: width }} data-testid='table-header-cell'>
            <div className={styles.headerCellContent}>
                {isSortable && sortArrow}

                <span onClick={isSortable ? onSort : undefined} className={titleClassNames}>
                    {title?.replace(/\w+/g, startCase)}
                </span>
            </div>
        </th>
    );
};

export default TableHeaderCell;
