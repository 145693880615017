import { useContext, useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';

import { TableContext } from 'src/shared/components/data-table/DataTable';

import { SortingDirection, type TableRowsSortingFunction } from '../dataTableTypes';

const useDataTable = () => {
    const dataTableContext = useContext(TableContext);

    if (!dataTableContext) {
        throw new Error('useDataTable must be used with DataTableContext Provider');
    }

    const { rows: allRows, currentPageNumber, rowsPerPage, columns, sortedColumn, dispatch, searchText } = dataTableContext;

    const [filteredRows, setFilteredRows] = useState(allRows);
    useEffect(() => {
        const filteredRows = !searchText
            ? allRows
            : [...allRows].filter(row => {
                  return row.cells.some((cell, index) => {
                      const { isSearchable = true } = columns[index];
                      if (!isSearchable) {
                          return false;
                      }
                      return cell?.toString().includes(searchText) || cell?.toString().toLowerCase().includes(searchText);
                  });
              });
        setFilteredRows(filteredRows);
    }, [searchText, allRows]);

    const getSortedCurrentPageRows = () => {
        let rows = [...filteredRows];
        const totalRows = filteredRows.length;
        const rowIndexLowerBound = (currentPageNumber - 1) * rowsPerPage;
        const rowIndexUpperBound = rowIndexLowerBound + rowsPerPage;

        if (sortedColumn && sortedColumn.sortedDirection !== SortingDirection.NONE) {
            const sortedColumnIndex = columns.findIndex(column => column.columnId === sortedColumn.columnId);

            const sortFunction = columns[sortedColumnIndex].sortFn ?? plainSorter;

            rows = sortFunction(rows, sortedColumnIndex, sortedColumn.sortedDirection);
        }

        return totalRows > rowsPerPage
            ? rows.filter((_, rowIndex) => !(rowIndex < rowIndexLowerBound || rowIndexUpperBound - 1 < rowIndex))
            : rows;
    };

    const setPageNumber = (value: number) => {
        dispatch({
            type: 'set_current_page_number',
            payload: value,
        });
    };

    return {
        getSortedCurrentPageRows,
        setPageNumber,
        filteredRows,
        ...dataTableContext,
    };
};

// useful for sorting strings and numbers in ascending or descending order
const plainSorter: TableRowsSortingFunction = (tableRows, columnIndex, direction) => {
    const iteratee = (data: any) => data.cells[columnIndex] as string | number;
    const orderDirection = direction === SortingDirection.DESC ? 'desc' : 'asc';
    return orderBy(tableRows, [iteratee], [orderDirection]);
};

export default useDataTable;
