import React from 'react';
import classNames from 'classnames';

import styles from './RadioGroup.module.scss';

export type OptionValue = string | number | boolean;
export type RadioOption = {
    value: OptionValue;
    label: string;
    isDisabled?: boolean;
};

export type RadioOptions = RadioOption[];
type Direction = 'row' | 'column';

export type RadioGroupProps = {
    onChange: (selectedOption: OptionValue) => void;
    options: RadioOptions;
    selectedOption: OptionValue;
    direction?: Direction;
    className?: string; // optional class name for the whole radio group element
    labelClassName?: string;
};

const RadioGroup = (props: RadioGroupProps) => {
    const direction = props.direction ?? 'column';

    const handleChange = (value: OptionValue) => {
        if (value === props.selectedOption) {
            return;
        }
        props.onChange(value);
    };

    const radioGroupClasses = classNames(
        styles.radioGroup,
        {
            [styles.radioGroupColumn]: direction === 'column',
            [styles.radioGroupRow]: direction === 'row',
        },
        props.className
    );
    const getRadioClass = (option: RadioOption) => {
        const isSelected = option.value === props.selectedOption;
        const isSelectedAndDisabled = isSelected && option.isDisabled;

        return classNames(styles.radio, {
            [styles.radioChecked]: isSelected,
            [styles.radioDisabled]: option.isDisabled,
            [styles.radioCheckedDisabled]: isSelectedAndDisabled,
        });
    };

    const getLabelClass = (option: RadioOption) =>
        classNames(styles.label, props.labelClassName, {
            [styles.labelDisabled]: option.isDisabled,
        });

    return (
        <div className={radioGroupClasses}>
            {props.options.map((option, index) => (
                <label key={index} className={styles.radioGroupItem}>
                    <span className={getRadioClass(option)} />
                    <span className={getLabelClass(option)}>{option.label}</span>
                    <input
                        className={styles.input}
                        type='radio'
                        onChange={() => handleChange(option.value)}
                        checked={option.value === props.selectedOption}
                        disabled={option.isDisabled}
                    />
                </label>
            ))}
        </div>
    );
};

export default RadioGroup;
