import React, { forwardRef, TextareaHTMLAttributes, ForwardedRef } from 'react';
import classNames from 'classnames';

import styles from './Textarea.module.scss';

export type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    resizable?: boolean;
};

const Textarea = (props: Props, ref: ForwardedRef<HTMLTextAreaElement>) => {
    const { className: classNameFromProps, resizable = true, ...otherProps } = props;

    const totalCharacters = String(otherProps.value || '').length;

    const className = classNames(styles.textarea, classNameFromProps, {
        [styles.disableResize]: !resizable,
    });

    return (
        <>
            <textarea ref={ref} className={className} {...otherProps} />
            {otherProps.maxLength && (
                <div className={styles.limit}>
                    {totalCharacters}/{otherProps.maxLength} Characters
                </div>
            )}
        </>
    );
};

export default forwardRef(Textarea);
