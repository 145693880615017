import fetch from 'cross-fetch';

import LRUCache from 'lru-cache';

import { JSONValue } from 'src/shared/types';

export enum HTTPMethod {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export type FetchOptions = {
    method?: HTTPMethod;
    headers?: { [key: string]: string };
    body?: string; // stringified json
    preserveEndpoint?: boolean;
    noCache?: boolean;
    signal?: AbortSignal;
};

const defaultMethod = HTTPMethod.GET;
const defaultHeaders = { Accept: 'application/json' };

export class ApiService {
    private cache: LRUCache<unknown, unknown>;
    private pendingCache: LRUCache<unknown, unknown>;

    public constructor() {
        this.cache = new LRUCache({
            max: 1000,
            maxSize: 5000,
            ttl: 60 * 60 * 1000,
        });

        this.pendingCache = new LRUCache({
            max: 1000,
            maxSize: 5000,
            ttl: 300,
        });
    }

    // method to enable easy testing
    public getFetch() {
        return fetch;
    }

    private buildFetchOptions(options: FetchOptions) {
        const headers: { [key: string]: string } = {
            ...defaultHeaders,
            ...options.headers,
        };
        if (
            options.method &&
            [HTTPMethod.POST, HTTPMethod.PUT, HTTPMethod.PATCH].includes(options.method) &&
            !headers.hasOwnProperty('Content-Type')
        ) {
            headers['Content-Type'] = 'application/json';
        }
        return {
            method: options.method || defaultMethod,
            headers: headers,
            body: options.body,
            signal: options.signal,
        };
    }

    public async fetch(url: string, options: FetchOptions = {}): Promise<any> {
        // Check cache first
        const cachedItem = options.noCache ? null : this.cache.get(url);
        if (cachedItem) {
            return cachedItem;
        }

        const fetchOptions = this.buildFetchOptions(options);
        const abortController = new AbortController();
        const signal = abortController.signal;
        fetchOptions.signal = signal;

        try {
            if (!options.noCache) {
                this.pendingCache.set(url, true, { size: 1 });
            }

            const response = await fetch(url, fetchOptions);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const processedResponse = await this.handleResponse(response, fetchOptions);

            if (!options.noCache) {
                this.cache.set(url, processedResponse, { size: 1 });
            }

            return processedResponse;
        } catch (error: unknown) {
            if ((error as JSONValue)?.name !== 'AbortError') {
                console.error('Fetch error:', error);
            }
            throw error; // Rethrow the error for the caller to handle
        } finally {
            if (!options.noCache) {
                this.pendingCache.delete(url); // Cleanup pending cache entry
            }
        }
    }

    private async handleResponse(response: Response, options: FetchOptions) {
        if (options.headers && options.headers['Accept'] === 'application/json') {
            return await response.json();
        } else {
            return await response.text();
        }
    }
}

const apiService = new ApiService();

export default apiService;
