import React, { ReactElement, ReactNode } from 'react';
import MUISelect, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { Radio } from '@mui/material';
import classNames from 'classnames';
import styles from './Select.module.scss';
import Tooltip from '../tooltip/Tooltip';

export type SelectOption = {
    value: string;
    label: ReactNode;
    selectedLabel?: string;
};

const menuProps = {
    anchorOrigin: {
        vertical: 'bottom' as const,
        horizontal: 'left' as const,
    },
    transformOrigin: {
        vertical: 'top' as const,
        horizontal: 'left' as const,
    },
    getcontentanchorel: null,
    classes: {
        list: styles.list,
    },
};

type SelectProps = {
    options: SelectOption[];
    isDisabled?: boolean;
    onChange: (value: string) => void;
    selectedValue: string | undefined;
    placeholder?: string;
    label?: string;
    className?: {
        select?: string;
        container?: string;
    };
    width?: number | string;
    tooltip?: ReactElement;
    renderValueFormatter?: (value: string, options: SelectOption[]) => string;
};

const Select = (props: SelectProps) => {
    const {
        options,
        onChange,
        selectedValue,
        label,
        width = 150,
        isDisabled,
        tooltip,
        placeholder,
        className,
        renderValueFormatter,
    } = props;

    const onChangeHandler = (e: SelectChangeEvent) => {
        onChange(e.target.value);
    };

    const selectClassNames = classNames(styles.select, className?.select);
    const selectContainerClassNames = classNames(styles.selectContainer, className?.container);

    const selectComponent = (
        <MUISelect
            labelId='selectLabel'
            value={selectedValue}
            label={label}
            placeholder={placeholder}
            MenuProps={menuProps}
            disabled={isDisabled}
            onChange={onChangeHandler}
            className={selectClassNames}
            displayEmpty
            renderValue={value =>
                value
                    ? renderValueFormatter
                        ? renderValueFormatter(value, options)
                        : options.find(option => option.value === value)?.label
                    : placeholder || 'None selected'
            }
        >
            {options.map((option, i) => (
                <MenuItem value={option.value} key={i}>
                    <Radio
                        size='small'
                        checked={selectedValue === option.value}
                        value={selectedValue}
                        name='radio-buttons'
                        sx={{ mr: 1 }}
                    />
                    {option.label}
                </MenuItem>
            ))}
        </MUISelect>
    );

    return (
        <div className={selectContainerClassNames} style={{ width }}>
            <FormControl size='small' sx={{ width: '100%' }}>
                {label && <InputLabel id={'selectLabel'}>{label}</InputLabel>}
                {tooltip ? (
                    <Tooltip content={tooltip} placement='bottom'>
                        {selectComponent}
                    </Tooltip>
                ) : (
                    selectComponent
                )}
            </FormControl>
        </div>
    );
};

export default Select;
