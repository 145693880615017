import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Root from './root/Root';

import configureStore from './store';

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <StrictMode>
                <Root />
            </StrictMode>
        </BrowserRouter>
    </Provider>
);
