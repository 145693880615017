import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { TaxonomicChartColumns } from '../../views/taxonomic-composition/components/taxonomic-composition-chart/taxonomicCompositionChartHelpers';
import { SamplingEventFilterData } from 'src/shared/hooks/useProject';

export const columnOrderOptions = [
    {
        label: 'Highest rank to lowest rank',
        value: 'high_to_low',
    },
    {
        label: 'Lowest rank to highest rank',
        value: 'low_to_high',
    },
];

export const classificationNamingOptions = [
    {
        label: 'Latin names',
        value: 'latin',
    },
];
export const baselineOptions = [
    {
        label: 'Historic site data',
        value: 'historic',
    },
];
export const metricOptions = [
    {
        label: 'Observations of species across samples',
        value: 'total_samples',
    },
];

export enum TaxonomicChartSortOptions {
    metric_high_to_low = 'metric_high_to_low',
    metric_low_to_high = 'metric_low_to_high',
    alphabetically_a_to_z = 'alphabetically_a_to_z',
    alphabetically_z_to_a = 'alphabetically_z_to_a',
}

export const sortOptions = [
    {
        label: 'Metric - high to low',
        value: TaxonomicChartSortOptions.metric_high_to_low,
    },
    {
        label: 'Metric - low to high',
        value: TaxonomicChartSortOptions.metric_low_to_high,
    },
    {
        label: 'Alphabetically (A-Z)',
        value: TaxonomicChartSortOptions.alphabetically_a_to_z,
    },
    {
        label: 'Alphabetically (Z-A)',
        value: TaxonomicChartSortOptions.alphabetically_z_to_a,
    },
];

export const columnOptions = [
    {
        label: 'Kingdom',
        value: TaxonomicChartColumns.tax_kingdom,
    },
    {
        label: 'Phylum',
        value: TaxonomicChartColumns.tax_phylum,
    },
    {
        label: 'Class',
        value: TaxonomicChartColumns.tax_class,
    },
    {
        label: 'Order',
        value: TaxonomicChartColumns.tax_order,
    },
    {
        label: 'Family',
        value: TaxonomicChartColumns.tax_family,
    },
    {
        label: 'Genus',
        value: TaxonomicChartColumns.tax_genus,
    },
    {
        label: 'Species',
        value: TaxonomicChartColumns.tax_species,
    },
];

export type TaxonomicCompositionFiltersState = {
    baseline: string | undefined;
    classificationNaming: string | undefined;
    areas: string[];
    kingdoms: string[];
    phylums: string[];
    classes: string[];
    metric: string;
    hierarchy: string;
    sortColumnsBy: string;
    selectedColumns: TaxonomicChartColumns[];
    highlightedBranchNodeId: string | null;
    visibleBranchNodeId: string | null;
    samplingEvent: SamplingEventFilterData | null;
};

export const defaultFiltersState: TaxonomicCompositionFiltersState = {
    baseline: baselineOptions[0].value,
    classificationNaming: classificationNamingOptions[0].value,
    areas: [],
    kingdoms: [],
    phylums: [],
    classes: [],
    metric: metricOptions[0].value,
    hierarchy: columnOrderOptions[0].value,
    sortColumnsBy: sortOptions[0].value,
    selectedColumns: [],
    highlightedBranchNodeId: null,
    visibleBranchNodeId: null,
    samplingEvent: null,
};

export type TaxonomicCompositionUIState = {
    filters: TaxonomicCompositionFiltersState;
    filtersDraft: Partial<TaxonomicCompositionFiltersState>;
};

export const defaultUiState = {
    filters: { ...defaultFiltersState },
    filtersDraft: { ...defaultFiltersState },
};

type TaxonomicCompositionState = {
    uiState: {
        [projectId: string]: {
            [habitatAssayType: string]: TaxonomicCompositionUIState;
        };
    };
};
const initialState: TaxonomicCompositionState = {
    uiState: {},
};

export const updateFilters =
    (fragment: Partial<TaxonomicCompositionFiltersState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;
        const habitatAssayType = state.projects.projectList.uiState[projectId as string].filters.habitatAssayType;

        if (projectId) {
            dispatch(
                taxonomicCompositionSlice.actions.updateFilters({
                    projectId,
                    habitatAssayType: habitatAssayType?.key || '',
                    fragment,
                })
            );
        }
    };

export const updateFiltersDraft =
    (fragment: Partial<TaxonomicCompositionFiltersState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;
        const habitatAssayType = state.projects.projectList.uiState[projectId as string].filters.habitatAssayType;

        if (projectId) {
            dispatch(
                taxonomicCompositionSlice.actions.updateFiltersDraft({
                    projectId,
                    habitatAssayType: habitatAssayType?.key || '',
                    fragment,
                })
            );
        }
    };

const ensureProjectUIStatePresence = (state: TaxonomicCompositionState, projectId: string, habitatAssayType: string) => {
    if (!state.uiState[projectId]) {
        state.uiState[projectId] = {
            [habitatAssayType]: {
                filters: { ...defaultFiltersState },
                filtersDraft: {},
            },
        };
    } else if (!state.uiState[projectId][habitatAssayType]) {
        state.uiState[projectId][habitatAssayType] = {
            filters: { ...defaultFiltersState },
            filtersDraft: { ...defaultFiltersState },
        };
    }
};

const taxonomicCompositionSlice = createSlice({
    name: 'taxonomicComposition',
    initialState,
    reducers: {
        updateFilters(
            state,
            action: PayloadAction<{ projectId: string; habitatAssayType: string; fragment: Partial<TaxonomicCompositionFiltersState> }>
        ) {
            const { projectId, fragment, habitatAssayType } = action.payload;

            ensureProjectUIStatePresence(state, projectId, habitatAssayType);

            state.uiState[projectId][habitatAssayType].filters = {
                ...(state.uiState[projectId][habitatAssayType].filters as TaxonomicCompositionFiltersState),
                ...fragment,
            };

            state.uiState[projectId].filtersDraft = state.uiState[projectId].filters;
        },
        updateFiltersDraft(
            state,
            action: PayloadAction<{ projectId: string; habitatAssayType: string; fragment: Partial<TaxonomicCompositionFiltersState> }>
        ) {
            const { projectId, fragment, habitatAssayType } = action.payload;

            ensureProjectUIStatePresence(state, projectId, habitatAssayType);

            state.uiState[projectId][habitatAssayType].filtersDraft = {
                ...(state.uiState[projectId][habitatAssayType].filtersDraft as TaxonomicCompositionFiltersState),
                ...fragment,
            };
        },
    },
});

export default taxonomicCompositionSlice.reducer;
