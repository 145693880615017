import React, { useEffect } from 'react';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';

import useScrollToTop from 'src/shared/hooks/useScrollToTop';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { reset } from 'src/app/projects/views/new-project-definition/state/newProjectDefinitionSlice';
import { SecondaryButton } from 'src/shared/components/button/Button';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import Confirm from 'src/shared/components/confirm/Confirm';
import WarningMessage from 'src/shared/components/warning-message/WarningMessage';
import { useAppDispatch } from 'src/store';
import StepIndicator from 'src/shared/components/step-indicator/StepIndicator';
import useProjectDefinition from './hooks/useProjectDefinition';
import styles from './NewProjectDefinition.module.scss';
import Samples from './components/samples/Samples';
import ReviewAndSubmit from './components/review-and-submit/ReviewAndSubmit';
import SamplingCadence from './components/sampling-cadence/SamplingCadence';
import BottomNavigation from './components/bottom-navigation/BottomNavigation';
import ProjectAims from './components/project-aims/ProjectAims';
import ProjectDetails from './components/project-details/ProjectDetails';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';

export const NewProjectDefinition = () => {
    const dispatch = useAppDispatch();
    const { currentCustomerId } = useCustomer();
    const navigate = useAppNavigation();
    const { formStep, saveProjectDefinitionForm, canCurrentUserContinueForm, isEditMode } = useProjectDefinition();

    useEffect(() => {
        if (!currentCustomerId) {
            navigate.toCustomers();
        }
        return () => {
            dispatch(reset());
        };
    }, [currentCustomerId]);

    const ref = useScrollToTop(formStep);

    const mainContent = (
        <div className={styles.projectDefinition} ref={ref}>
            <StepIndicator
                currentStep={formStep}
                steps={['Project details', 'Project aims', 'Sampling cadence', 'Samples', 'Review and submit']}
            />
            {formStep === 4 && <ReviewAndSubmit />}
            <div className={styles.formSection}>
                {formStep === 0 && <ProjectDetails />}
                {formStep === 1 && <ProjectAims />}
                {formStep === 2 && <SamplingCadence />}
                {formStep === 3 && <Samples />}
                {formStep !== 4 && <BottomNavigation />}
            </div>
        </div>
    );

    const onSave = () => {
        saveProjectDefinitionForm();
        return;
    };

    const saveMessage = (
        <WarningMessage
            message={
                <>
                    This project definition is NOT submitted.
                    <br />
                    <br />
                    It will be retained with ‘incomplete’ status for 5 days, after which it will be deleted.
                    <br />
                    <br />
                    This incomplete project definition will only be editable by you.
                </>
            }
        />
    );

    const otherActions = !isEditMode && formStep !== 4 && canCurrentUserContinueForm && (
        <div className={styles.otherActions}>
            <Confirm
                onConfirm={onSave}
                title='Are you sure you want to save and exit from this project definition?'
                content={saveMessage}
                width={'500px'}
            >
                <SecondaryButton>
                    <SaveOutlined />
                    Save and exit
                </SecondaryButton>
            </Confirm>
        </div>
    );

    const pageTitle = isEditMode ? 'Edit project definition' : 'New project definition';

    return <StandardAppLayout mainContent={mainContent} title={pageTitle} otherActions={otherActions} />;
};

export default NewProjectDefinition;
