import * as React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import styles from './BasicDatePicker.module.scss';

type BasicDatePickerProps = {
    label?: string;
    value: Date | null;
    disablePast?: boolean;
    onChange: (value: Date | undefined) => void;
};

const BasicDatePicker = (props: BasicDatePickerProps) => {
    const { label, value, onChange, disablePast } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={label}
                value={value ? moment(value || null) : null}
                onChange={value => onChange(value?.toDate())}
                className={styles.basicDatePicker}
                format='DD/MM/YYYY'
                disablePast={disablePast}
            />
        </LocalizationProvider>
    );
};

export default BasicDatePicker;
