import { DataTableState, AllTableActions } from './dataTableTypes';

export const defaultDataTableState: DataTableState = {
    data: [],
    rowsPerPage: 15,
    currentPageNumber: 1,
    searchText: '',
    sortedColumn: null,
    fixedHeader: false,
    hiddenColumnIds: new Set(),
};

export const tableReducer = (state: DataTableState, action: AllTableActions): DataTableState => {
    switch (action.type) {
        case 'set_rows_per_page':
            return { ...state, rowsPerPage: action.payload, currentPageNumber: 1 };
        case 'set_search_text':
            return { ...state, searchText: action.payload, currentPageNumber: 1 };
        case 'set_current_page_number':
            return { ...state, currentPageNumber: action.payload };
        case 'set_sorted_column':
            return { ...state, sortedColumn: action.payload };
        case 'clear_sorted_column':
            return { ...state, sortedColumn: null };
        case 'restore_defaults':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
