import { gql } from 'graphql-request';
import { Customer } from 'src/shared/types';
import { Project, ProjectFilters } from 'src/shared/types';

export const projectsQuery = gql`
    query getProjectList($customerId: String!) {
        customer(customerId: $customerId) {
            customerId
            customerName
            projects {
                projectId
                projectName
                projectCode
                projectType
                projectEndDate
                griisLookupCountries
                area
                country
                targetOutcome
                isChegdProject
                habitatAssay {
                    habitatName
                    assayName
                    habitatAssayKey
                }
                samplingCadence {
                    type
                    samplingEventsPerYear
                    events {
                        name
                        fromDate
                        toDate
                        year
                        processed
                        processedDate
                    }
                }
                projectLifespanYears
                sampleGroups
                speciesOfInterest
                samplesCount
                estimatedSampleCounts
                estimatedSampleCountForTestTypes {
                    type {
                        habitatName
                        assayName
                        habitatAssayKey
                    }
                    count
                }
                initialSampleEntries {
                    sampleId
                    samplePoint
                    sampleGroup
                    kitType
                    latitude
                    longitude
                    habitatAssayTypes {
                        habitatName
                        assayName
                        habitatAssayKey
                    }
                }

                leadName
                leadEmail
                leadRole
                subscriptionType
                subscription {
                    name
                    label
                }
                files(fileType: "reports") {
                    name
                    awsFileKey
                    fileCreationDate
                }
                country
                createdAt
                creatorName
                updatedAt
                updaterName
                status
                notifyUsersOnChange
                hasDefinedSampleEvents
                survey {
                    projectId
                    projectName
                    projectCode
                    projectType
                    area
                    country
                    targetOutcome
                    projectOutline
                    surveyPublishDate
                    surveySamples {
                        sampleId
                        samplePoint
                        sampleGroup
                        kitType
                        latitude
                        longitude
                        habitatAssayTypes {
                            habitatName
                            assayName
                            habitatAssayKey
                        }
                    }
                    habitatAssayTypes {
                        habitatName
                        assayName
                        habitatAssayKey
                    }
                    samplingCadence {
                        type
                        samplingEventsPerYear
                        events {
                            name
                            fromDate
                            toDate
                        }
                    }
                    sampleGroups
                    speciesOfInterest
                    leadName
                    leadEmail
                    leadRole
                    subscriptionType
                    subscription {
                        name
                        label
                    }
                    country
                    createdAt
                    creatorName
                    status
                    sites {
                        type
                        uploadedAt
                        uploadedBy
                        properties {
                            name
                            area
                            status
                            statusMessage
                            metrics {
                                metric
                                layers {
                                    year
                                    status
                                    url
                                    boundaryUrl
                                }
                            }
                        }
                        geometry {
                            type
                            coordinates
                        }
                    }
                }
            }
        }
    }
`;

export type ProjectsQueryResult = {
    customer: Customer;
};

export const projectQuery = gql`
    query projects($projectId: String!) {
        project(projectId: $projectId) {
            projectId
            projectName
            projectStartDate
            latestReportingDate
            griisLookupCountries
            samples {
                location {
                    coordinates
                }
                sampleId
                sampleCollectedDatetime
                sampleDeployDatetime
            }
        }
    }
`;

export type ProjectQueryResult = {
    project: Project;
};

export const projectFiltersQuery = gql`
    query getFilters($projectId: String!) {
        filters(projectId: $projectId) {
            map {
                times
                areas
                habitatAssays
                hlcaSites
                hlcaChartTypes
            }
        }
    }
`;

export type ProjectFiltersQueryResult = {
    filters: ProjectFilters;
};

export const customerProjectsInBCQuery = gql`
    query getCustomertListWithProjects($customerId: String!) {
        customerProjectsInBC(customerId: $customerId) {
            customerId
            customerName
            projects {
                projectId
                projectName
                projectCode
                leadName
                leadEmail
            }
        }
    }
`;

export type CustomerProjectsInBCQueryResult = {
    customerProjectsInBC: Pick<Customer, 'customerId' | 'customerName' | 'projects'>;
};
