import { isEqual } from 'lodash';

import turfBuffer from '@turf/buffer';
import { Units, Feature } from '@turf/helpers';

export const isValidLatitude = (lat: string | number) => {
    if (typeof lat !== 'string' && typeof lat !== 'number') return false;

    const latitude = parseFloat(String(lat));

    // Check if latitude is a number and lies between -90 and 90 inclusive
    return !isNaN(latitude) && latitude >= -90 && latitude <= 90;
};

export const isValidLongitude = (lon: string | number) => {
    if (typeof lon !== 'string' && typeof lon !== 'number') return false;

    const longitude = parseFloat(String(lon));

    // Check if longitude is a number and lies between -180 and 180 inclusive
    return !isNaN(longitude) && longitude >= -180 && longitude <= 180;
};

export const swapCoordinateLatLng = ([lng, lat]: [number, number]): [number, number] => [lat, lng];

type GetBufferedPolygonParams = {
    coordinates: [number, number][];
    units?: Units;
    radius?: number;
};

export const getBufferedPolygon = (params: GetBufferedPolygonParams) => {
    const { coordinates, units = 'kilometers', radius = 1 } = params;

    const workingCoordinates = [...coordinates];
    const firstCoordinate = workingCoordinates[0];
    const lastCoordinate = workingCoordinates.at(-1);
    if (!isEqual(firstCoordinate, lastCoordinate)) {
        workingCoordinates.push(workingCoordinates[0]);
    }

    const polygonFeature = {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'Polygon',
            coordinates: [workingCoordinates], // Wrapping the coordinates array in another array as it's a polygon
        },
    } as Feature;

    // Create a buffer around the polygon using Turf.js
    const bufferedPolygon = turfBuffer(polygonFeature, radius, { units });

    return bufferedPolygon;
};
